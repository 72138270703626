<template>
  <ion-text class="ion-text-center" color="primary">
    <h2>{{whatIsShown}}</h2>
  </ion-text>
  <ion-text class="ion-text-center" color="secondary" v-if="whereWeAre">
    <h3>{{whereWeAre}}</h3>
  </ion-text>
  <ion-grid>
    <ion-row class="ion-align-items-center">
      <ion-col
        size="12"
        :size-md="territories.length == 1 ? 12 : 6"
        :size-lg="territories.length == 1 ? 12 : territories.length == 2 ? 6 : 4"
        @click="$router.replace(v.route)"
        v-for="v in territories"
        :key="v.id"
      >
<!--
          :push-lg="v.pushInLg"
        :pull-lg="v.pullInLg"
        :push-md="v.pushInMd"
        :pull-md="v.pullInMd"
-->
        {{ v.name }}
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script lang="ts">
import { IonGrid, IonCol, IonRow, IonText } from "@ionic/vue";
import { defineComponent } from "vue";
import cyanRegions, { territorios } from "@/modules/cyanRegions";
import unreact from '@/modules/unreact';

interface ElementoMapa {
  id: number;
  name: string;
  route: string;
  pullInMd: number | "";
  pullInLg: number | "";
  pushInMd: number | "";
  pushInLg: number | "";
}

export default defineComponent({
  name: "TerritoryPicker",
  components: {
    IonGrid,
    IonCol,
    IonRow,
    IonText
  },
  data() {
    return {
      seed: unreact(this.seedTP+'') ?? cyanRegions.base
    };
  },
  computed: {
    whatIsShown: function(): string {
      if (! (this.seed.length in cyanRegions.divisiones)) return '';
      const lh = cyanRegions.divisiones[this.seed.length].largoHijos;
      if (!lh) return '';
      return cyanRegions.divisiones[lh+''].plural.toUpperCase();
    },
    whereWeAre: function(): string {
      return this.seed in territorios ? territorios[this.seed].nombre : '';
    },
    territories: function (): ElementoMapa[] {
      if (!(this.seed in cyanRegions.mapa)) {
        return [
          {
            id: -1,
            name: "",
            route: "/home",
            pushInMd: "",
            pushInLg: "",
            pullInMd: "",
            pullInLg: "",
          },
        ];
      }

      const codigosElementos = cyanRegions.mapa[this.seed].sort(function (
        a,
        b
      ) {
        const t = territorios as any;
        const na = t[a].nombre;
        const nb = t[b].nombre;
        return na < nb ? -1 : na == nb ? 0 : 1;
      });
      const valoresElementos: any = {};
      
      for (const x of codigosElementos)
        valoresElementos[x] = cyanRegions.territorios[x];

      const r: ElementoMapa[] = [];
      let idx;
      let id = 1;

      const numEl = codigosElementos.length;
      const mdBreak = Math.ceil(numEl / 2);
      const lgBreak = Math.ceil(numEl / 3);

      for (idx of codigosElementos) {
        // id tiene la posición actual, basada en 1
        // cuando es 1 columna, esa misma
        // cuando son 2 columnas, en primer lugar debemos
        // averiguar a cuál va, y a partir de ahí

        const colInMd = Math.ceil(id / mdBreak);
        const offsetInMd = colInMd + 2 * ((id - 1) % mdBreak);

        const colInLg = Math.ceil(id / lgBreak);
        const offsetInLg = colInLg + 3 * ((id - 1) % lgBreak);

        const pullInMd = offsetInMd < id ? 6*(id - offsetInMd) : "";
        const pushInMd = offsetInMd > id ? 6*(offsetInMd - id) : "";
        const pullInLg =
          offsetInLg < id ? 3*(id - offsetInLg) : "";
        const pushInLg =
          offsetInLg > id ? 3*(offsetInLg - id) : "";

        r.push({
          id: id++,
          name: valoresElementos[idx as any].nombre,
          route: this.nextRoute + idx,
          pullInMd,
          pullInLg,
          pushInMd,
          pushInLg,
        });
      }

      return r;
    },
  },
  props: {
    seedTP: {
      type: String,
      default: cyanRegions.base,
    },
    nextRoute: {
      type: String,
    },
  },
  methods: {},
});
</script>

<style scoped>
ion-col {
  --ion-grid-column-padding: 5px;
  font-weight: 500;
  cursor: pointer;
  color: var(--ion-color-dark);
  text-align: center;
  border-radius: 5px;
}
ion-col:hover {
  background: var(--cyan-lighter-background);
}
</style>